var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('French Section')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.name","rules":"required","name":"The Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Name'),"name":"name","fou":""},on:{"keyup":_vm.generateSomeFields},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.url","rules":"required","name":"The URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('URL'),"name":"url","fou":""},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}})]}}])})],1)])]),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Is Active')))]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }